<template>
  <div class="board">
    <div class="board_row">
      <div class="board-row-head">
        <div class="board-row-head-title base-font-color bold"><img src="@/static/board/icon-develop-performance.png" />绩效列表
        </div>
        <div class="board-row-head-query">
          <Button type="primary" style="margin-right:5px;">保存</Button>
          <Button type="primary" style="margin-right:5px;">导出汇总</Button>
        </div>
      </div>
      <div class="board-row-centent">
        <div class="board-row-centent-head">
          <div class="board-row-centent-head-title">
            <Col span="12">
            <DatePicker type="month" placeholder="Select month" style="width: 200px"></DatePicker>
            </Col>
          </div>
          <div class="board-row-head-query">
            <Poptip placement="left-start">
              <a class="base-font-color" style="color:#0578FE"><img src="@/static/board/icon-develop-info.png" />绩效考核办法说明v1.0</a>
              <div slot="content" style="width:700px">

                <!-- v-html="content" -->
                <div class="light-dark-font-color"><label class="dark-font-color">1.当月考核工时：</label>每个月按照法定节假日天数*8小时(不含单休周6)</div>
                <div class="light-dark-font-color"><label class="dark-font-color">2.当月扣减工时：</label>因请假或其他客观因素导致的缺失工时</div>
                <div class="light-dark-font-color"><label class="dark-font-color">3.最终考核工时：</label>最终需要达成的工时=当月考核工时-当月扣减工时</div>
                <div class="light-dark-font-color"><label class="dark-font-color">4.应完成工时：</label>截止当天，应完成的工时</div>
                <div class="light-dark-font-color"><label class="dark-font-color">5.应完成绩效：</label>截止当天，应完成的绩效比例=应完成工时/最终考核工时</div>
                <div class="light-dark-font-color"><label class="dark-font-color">6.工作计划工时：</label>禅道记录的本月工作计划总工时</div>
                <div class="light-dark-font-color"><label class="dark-font-color">7.计划剩余工时：</label>禅道记录的本月工作计划未完成工时</div>
                <div class="light-dark-font-color"><label class="dark-font-color">8.实际完成工时：</label>禅道记录的本月工作计划已完成的工时=工作计划工时-预计剩余工时</div>
                <div class="light-dark-font-color"><label class="dark-font-color">9.实际消耗工时：</label>禅道记录的本月工作计划已完成的任务实际消耗工时(仅作参考，不做计算)</div>
                <div class="light-dark-font-color"><label class="dark-font-color">10.绩效比例：</label>试剂完成的绩效比例=计划完成工时/最终考核工时</div>
                <div class="flex-row">
                  <div class="fill-row5">
                    <p class="dark-font-color">注：（工作单位：小时）</p>
                    <p class="dark-font-color">计算规则</p>
                    <p class="light-dark-font-color">绩效工资=基本工资*20%*绩效比例</p>
                    <p class="light-dark-font-color">每月工资=基本工资*80%+绩效工资</p>
                  </div>
                  <div class="fill-row5">
                    <p class="dark-font-color">注</p>
                    <p class="light-dark-font-color">1. 绩效比例&lt;60%，按照0计算</p>
                    <p class="light-dark-font-color">2. 绩效比例&gt;150%，按照150计算</p>
                    <p class="light-dark-font-color">3. 90%&lt;绩效比例&lt;110% ，按照100%计算</p>
                    <p class="light-dark-font-color">4. 其他按照实际比例计算</p>
                  </div>
                </div>
              </div>
            </Poptip>
          </div>
        </div>
        <div class="board-row-centent-table">
          <Table row-key="id" @on-row-cilick="rowclick" @on-cell-click="cellclick" size="small"  :columns="performance_columns" :data="performance_data" border></Table>
        </div>
      </div>
    </div>
    <div class="board_row">
      <div class="board-row-head">
        <div class="board-row-head-title base-font-color bold" style="color:"><img src="@/static/board/icon-develop-workhour.png" />工时统计
        </div>
        <div class="board-row-head-query">
          <Button type="primary" style="margin-right:5px;">导出</Button>
        </div>
      </div>
      <div class="board-row-centent">
        <div class="board-row-centent-table">
          <!-- show-summary -->
          <Table row-key="id" @on-row-cilick="rowclick" @on-cell-click="cellclick" size="small"  :columns="workhour_columns" :data="workhour_data" border></Table>
        </div>
      </div>
    </div>
    <div class="board_row">
      <div class="board-row-head">
        <div class="board-row-head-title base-font-color bold"><img src="@/static/board/icon-develop-project.png" />项目统计
        </div>
        <div class="board-row-head-query">
          <Button type="primary" style="margin-right:5px;">导出</Button>
        </div>
      </div>
      <div class="board-row-centent">
        <div class="board-row-centent-table">
          <Table :columns="project_columns" :data="project_data" border>
          </Table>
        </div>
      </div>
    </div>

    <Drawer title="历史记录" :closable="false" v-model="drawer_visable" width="30">
      <Timeline pending color="blue">
        <TimelineItem v-for="(item,index) in history" :key="index">
          <p class="base-font-color">{{item.time}}</p>
          <p class="dark-font-color"><label class="light-font-color">项目名称：</label>{{item.projectname}}</p>
          <p class="dark-font-color"><label class="light-font-color">记录人员：</label>{{item.user}}</p>
          <p class="dark-font-color"><label class="light-font-color">消耗时间：</label>{{item.use}}</p>
          <p class="dark-font-color" style="background:#EEEEEE">{{item.content}}</p>
        </TimelineItem>
      </Timeline>
    </Drawer>
  </div>
</template>
<script>
import { formatDate } from "@/utils/format.js";
import { GetTeamGoalDetail, SaveTeamGoal } from "@/api";
import { loadDetailAction, submitAction } from "@/utils/action.js";
import table from '@/components/web/tablebox'
export default {
  data: function () {
    return {
      goalShow: false,
      rankType: "区域",
      cycle: 2021,
      cycles: [{ year: 2021 }],
      teamId: '0',
      teamUser: { title: '' },
      teamUsers: [],
      goalModel: { total: {} },
      userGoal: {},
      drawerStyles: {
        height: 'calc(100% - 55px)',
        overflow: 'auto',
        paddingBottom: '53px',
        position: 'static'
      },
      chartProduct: {},
      chartVisit: {},
      productOption: {
        legend: {
          top: 'bottom'
        },
        tooltip: {
          trigger: 'item'
        },
        color: ["#5470c6", "#91cc75", "#fac858", "#ee6666", "#73c0de", "#3ba272", "#fc8452", "#9a60b4", "#ea7ccc"],
        series: [
          {
            type: 'pie',
            radius: ["35%", "80%"],
            center: ["50%", "40%"],
            data: []
          }
        ]
      },
      visitOption: {
        grid: {
          top: '10px',
          left: '10px',
          right: '20px',
          bottom: '10px',
          containLabel: true
        },
        tooltip: {
          trigger: 'item'
        },
        color: ["#559AF4"],
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: []
        },
        series: [{
          type: 'bar',
          data: []
        }]
      },
      performance_columns: [
        {
          title: '考核工时',
          children: [
            {
              title: '当月考核工时',
              key: 'a',
            },
            {
              title: '当月扣减工时',
              key: 'b',
            },
            {
              title: '最终考核工时',
              key: 'c',
            }
          ],
          align: 'center',
        },
        {
          title: '计划工时',
          align: 'center',
          children: [
            {
              title: '工作计划工时',
              key: 'e',
            },
            {
              title: '计划剩余工时',
              key: 'f',
            },
            {
              title: '实际完成工时',
              key: 'g',
            },
            {
              title: '实际消耗工时',
              key: 'h',
            }
          ]
        },
        {
          title: '绩效比例',
          key: 'i',
        },
        {
          title: '备注',
          key: 'j',
        }
      ],
      performance_data: [
        {
          id: '100',
          name: 'John Brown',
          a: "1",
          age: 18,
          address: 'New York No. 1 Lake Park'

        },
        {
          id: '101',
          name: 'Jim Green',
          age: 24,
          address: 'London No. 1 Lake Park',
        },
        {
          id: '102',
          name: 'Joe Black',
          age: 30,
          address: 'Sydney No. 1 Lake Park'
        },
        {
          id: '103',
          name: 'Jon Snow',
          age: 26,
          address: 'Ottawa No. 2 Lake Park'
        },
        {
          id: '104',
          name: 'Jon Snow',
          age: 26,
          address: 'Ottawa No. 2 Lake Park'
        },
        {
          id: '105',
          name: 'Jon Snow',
          age: 26,
          address: 'Ottawa No. 2 Lake Park'
        }


      ],
      content: "",
      //抽屉
      drawer_visable: false,
      drawerItem: {
        jh: "计划",
        zj: "总结",
        endTime: "2021/11/24"
      },
      //单元格颜色
      workhour_columns: [
        {
          title: '姓名',
          key: 'name',
          width: 80,
        },
        {
          title: '1日',
          key: 'day1'
        },
        {
          title: '2日',
          key: 'day2'
        },
        {
          title: '3日',
          key: 'day3'
        },
        {
          title: '4日',
          key: 'day4'
        },

        {
          title: '5日',
          key: 'day5'
        },
        {
          title: '6日',
          key: 'day6'
        },
        {
          title: '7日',
          key: 'day7'
        },
        {
          title: '8日',
          key: 'day8'
        },
        {
          title: '合计',
          key: 'sum'
        },
      ],
      workhour_data: [
        {
          name: '成涛',
          day1: 8,
          day2: 7,
          day3: 6,
          day4: 7,
          day5: 9,
          day6: 1,
          day7: 1,
          day8: 1,
        },
        {
          name: '徐维鑫',
          day1: 1,
          day2: 2,
          day3: 3,
          day4: 4,
          day5: 5,
          day6: 6,
          day7: 7,
          day8: 8,
        },
        {
          name: '杨孟',
          day1: 9,
          day2: 12,
          day3: 2,
          day4: 5,
          day5: 5,
          day6: 35,
          day7: 7,
          day8: 5,
        },
        {
          name: '袁周',
          day1: 25,
          day2: 25,
          day3: 25,
          day4: 25,
          day5: 25,
          day6: 25,
          day7: 25,
          day8: 25,
        },
      ],
      //表单嵌套
      project_columns: [
        {
          type: 'expand',
          width: 50,
          render: (h, params) => {
            this.change_getinfo(params.row);
            return h(table, {
              props: {
                row: params.row,
                column: this.children_columns,
                data:[params.row],
              }
            })
          }
        },
        {
          title: '姓名',
          key: 'name'
        },
        {
          title: '年龄',
          key: 'age'
        },
        {
          title: 'Address',
          key: 'address'
        },
      ],
      project_data: [
        {
          name: 'John Brown',
          age: 18,
          address: 'New York No. 1 Lake Park',
          job: 'Data engineer',
          interest: 'badminton',
          birthday: '1991-05-14',
          book: 'Steve Jobs',
          movie: 'The Prestige',
          music: 'I Cry'
        },
        {
          name: 'Jim Green',
          age: 25,
          address: 'London No. 1 Lake Park',
          job: 'Data Scientist',
          interest: 'volleyball',
          birthday: '1989-03-18',
          book: 'My Struggle',
          movie: 'Roman Holiday',
          music: 'My Heart Will Go On'
        },
        {
          name: 'Joe Black',
          age: 30,
          address: 'Sydney No. 1 Lake Park',
          job: 'Data Product Manager',
          interest: 'tennis',
          birthday: '1992-01-31',
          book: 'Win',
          movie: 'Jobs',
          music: 'Don’t Cry'
        },
        {
          name: 'Jon Snow',
          age: 26,
          address: 'Ottawa No. 2 Lake Park',
          job: 'Data Analyst',
          interest: 'snooker',
          birthday: '1988-7-25',
          book: 'A Dream in Red Mansions',
          movie: 'A Chinese Ghost Story',
          music: 'actor'
        }
      ],
      children_columns: [
        {
          title: '姓名',
          key: 'name'
        },
        {
          title: '年龄',
          key: 'age'
        },
        {
          title: 'Address',
          key: 'address'
        },
        {
          title: '音乐',
          key: 'music'
        },
        {
          title: '书',
          key: 'book'
        },
        {
          title: '工作',
          key: 'job'
        },
      ],
      children_data:[],
      history: [
      ],
    }
  },
  methods: {
    formatDate: formatDate,
    initPage: function () {
      // loadDataAction(this, GetTeamUser, {}, (data) => {
      //   this.teamUsers = data;
      //   if (this.teamUsers.length > 0) {
      //     this.teamUser = this.teamUsers[0];
      //     this.teamId = this.teamUser.id;
      //   }
      //   this.dataRequest();
      // });
      this.performance_getInfo();
      this.workhour_getInfo();
      this.project_getInfo();
    },
    getUserGoal: function () {
      this.goalShow = true;
    },
    saveUserGoal: function () {
      this.goalShow = false;
      var model = { user_id: this.teamUser.id, user_name: this.teamUser.title.replace('├ ', '').trim(), cycle: this.cycle, type: 1, goal: this.userGoal.goal, memo: this.userGoal.memo };
      submitAction(this, SaveTeamGoal, { model: model }, () => { this.dataRequest(); });
    },
    dataRequest: function () {
      var selectTU = this.teamUsers.filter(tu => tu.id === this.teamId);
      this.teamUser = (selectTU.length === 1) ? selectTU[0] : { title: '' };

      loadDetailAction(this, GetTeamGoalDetail, { cycle: this.cycle, teamId: this.teamUser.id, teamType: this.teamUser.type }, (data) => {
        this.goalModel = data;
        this.userGoal = data && data.total ? data.total.userGoal : {};

        this.productOption.series[0].data = this.goalModel.rankProduct;
        this.chartProduct.setOption(this.productOption);

        this.visitOption.yAxis.data = this.goalModel.rankVisit.names;
        this.visitOption.series[0].data = this.goalModel.rankVisit.values;
        this.chartVisit.setOption(this.visitOption);
      });
    },
    cellclick(row, column, data, event) {
      console.log(row);
      console.log(column);
      console.log(data);
      console.log(event);
      this.history = [
        {
          time: "2021-01-03 12:12:12",
          projectname: "lims环科院",
          user: "张宏凯",
          use: "6",
          content: "设计生成原始记录Excel文件整理原始记录单查询所需要的sql"
        },
        {
          time: "2021-01-03 12:12:12",
          projectname: "lims环科院",
          user: "张宏凯",
          use: "6",
          content: "设计生成原始记录Excel文件整理原始记录单查询所需要的sql"
        }];
      this.drawer_visable = true;
    },
    rowclick(row) {
      console.log(row)
    },
    //获取绩效数据
    performance_getInfo() {

    },
    //获取工时数据
    workhour_getInfo() {
      this.workhour_columns.push()
      this.workhour_data.forEach(element => {
        this.$set(element, "cellClassName", {})
        this.$set(element, "sum", 0);
        Object.keys(element).forEach(key => {
          if (key != "sum" && key != "name" && key != "cellClassName") {
            if (element[key] > 8) {
              this.$set(element.cellClassName, key, "table-success-td")

              // element.cellClassName[key] = "table-success-td";
            } else if (element[key] < 8) {
              this.$set(element.cellClassName, key, "table-error-td")
              // element.cellClassName[key] = "table-error-td";
            } else {
              this.$set(element.cellClassName, key, "table-info-td")
              // element.cellClassName[key] = "table-info-td";
            }
            element.sum += element[key];
          }

        });
      });
    },
    //获取项目数据
    project_getInfo() {

    },
    change_getinfo(row){
      var row1=row;
      row=row1;
      this.children_data=this.project_data;
    },
  },
  mounted() {
    this.initPage();
  }
}
</script>
<style scoped>
</style>





